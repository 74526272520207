// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ref: {
    serviceUrl: 'https://services.foreststaking.com/',
    contactUrl: '/contact-us',
    tools: 'https://services.foreststaking.com/',
    news: 'https://medium.com/@foreststaking',
    feed: '/api',
    contacts: {
      tg: 'https://t.me/foreststaking',
      yt: 'https://www.youtube.com/@foreststaking',
      in: 'https://www.linkedin.com/company/76551068/admin/feed/posts/',
      fb: 'https://www.facebook.com/foreststaking',
      x: 'https://x.com/foreststaking',
      md: 'https://medium.com/@foreststaking/about',
      lr: 'https://linktr.ee/foreststaking',
      tell: '01158 88 2555',
      bookColl: 'https://calendly.com/foreststaking'
    },
    team: {
      Gary: {
        tg: '',
        x: "https://x.com/thatstreamerg",
        in: "https://www.linkedin.com/in/gary-mcneish-bb150817a"
      },
      Chris: {
        tg: '',
        x: "https://x.com/TheTaffForest",
        in: "https://www.linkedin.com/in/christopher-dowle/"
      },
      Kieran: {
        tg: '',
        x: "https://x.com/kie_forest",
        in: "https://www.linkedin.com/in/kieprospex"
      },
      Dale: {
        tg: '',
        x: '',
        in: 'https://www.linkedin.com/in/dale-ashworth-brown-09158b294/'
      },
      Harris: {
        tg: '',
        x: '',
        in: ''
      },
      David: {
        tg: '',
        x: '',
        in: 'https://www.linkedin.com/in/david-mp-kerrigan'
      },
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
