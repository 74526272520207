import { Component } from '@angular/core';
import {KeyValuePipe, NgForOf, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    KeyValuePipe,
    NgOptimizedImage,
    NgSwitch,
    NgSwitchCase
  ],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss'
})
export class TeamComponent {
  public ref = environment.ref;
  teamCards = [
    {
      name: 'Gary Mc.',
      photo: 'GaryMc@2x',
      idMember: 'team-member-GaryMc',
      idPosition: 'team-member-sto',
      position: ['Co-Founder, CEO', '& Chief Technical Officer'],
      follow: {
        telegram: this.ref.team.Chris.tg,
        linkid: this.ref.team.Chris.in,
        x: this.ref.team.Chris.x
      },
    },
    {
      name: 'Chris D.',
      photo: 'ChrisD@2x',
      idMember: 'team-member-ChrisD',
      idPosition:'team-member-coo',
      position: ['Co-Founder', '& Chief Operations Officer'],
      follow: {
        telegram: this.ref.team.Chris.tg,
        linkid: this.ref.team.Chris.in,
        x: this.ref.team.Chris.x
      },
    },
    {
      name: 'Kieran F.',
      photo: 'KieranF@2x',
      idMember: 'team-member-KieranF',
      idPosition:'team-member-hsr',
      position: ['Co-Founder & Head of', 'Strategic Relationships'],
      follow: {
        telegram: this.ref.team.Kieran.tg,
        linkid: this.ref.team.Kieran.in,
        x: this.ref.team.Kieran.x
      },
    },
    {
      name: 'Dale A.',
      photo: 'DaleA@2x',
      idMember: 'team-member-DaleA',
      idPosition:'team-member-ie',
      position: ['Infrastructure', 'Engineer'],
      follow: {
        telegram: this.ref.team.Dale.tg,
        linkid: this.ref.team.Dale.in,
        x: this.ref.team.Dale.x
      },
    },
    {
      name: 'Harris K.',
      photo: 'HarrisK@2x',
      idMember: 'team-member-HarrisK',
      idPosition:'team-member-ie_1',
      position: ['Infrastructure', 'Engineer'],
      follow: {
        telegram: this.ref.team.Harris.tg,
        linkid: this.ref.team.Harris.in,
        x: this.ref.team.Harris.x
      },
    },
    {
      name: 'David K.',
      photo: 'DavidK@2x',
      idMember: 'team-member-DavidK',
      idPosition:'team-member-ie_2',
      position: ['Infrastructure', 'Engineer'],
      follow: {
        telegram: this.ref.team.David.tg,
        linkid: this.ref.team.David.in,
        x: this.ref.team.David.x
      },
    },
  ];
}
